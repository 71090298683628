<template>
  <div class="Page-Wrapper">
    <SortingWithFiltersPreset
      :title="'Заказы с магазина'"
      :originalArray="getProducts"
      :searchAttribures="filters.searchInProperties"
      :setNewArrayCallback="handleSearchFilterSortingObject"
      :sortMethodCallback="sortingMethods"
      :filtersButtons="filters.filtersButtons"
      :sortOptions="filters.sortingOptions"
      :callbackForFilterButton="handleFilterButton"
      :filterButtonModeServer="true"
      :setFiltersData="{
        button: filters.selectedButton,
        search: filters.selectedSearch,
        sorting: filters.selectedSorting,
      }"
      :postFiltersDataChanges="handleFilterChanges"
      :key="'SortingFiltersSearch-' + products_hash + getProducts"
    />

    <div class="Orders-List" :key="'product-block' + products_filtered_hash">
      <div
        v-for="(p, pindx) in store.products_filtered"
        :key="pindx + 'product-' + p.id"
        class="Orders-Card noselect"
      >
        <div class="OC-Block">
          <div
            class="OCB-Image"
            :style="
              getStyleFromUploadedFile(
                p?.OrderProducts?.ProductImages?.[0] == null
                  ? '#'
                  : p?.OrderProducts?.ProductImages?.[0]?.url_file
              )
            "
          >
            <span lang="ru" v-if="p?.OrderProducts?.ProductImages?.[0] == null"
              >Изображения<br />нет</span
            >
          </div>
          <div class="OCB-Vertical">
            <span>Товар</span>
            <span>{{ p?.OrderProducts?.name }}</span>
          </div>
          <div class="OCB-Vertical">
            <span>Цена</span>
            <span>{{ p?.total_price }}</span>
          </div>
          <div class="OCB-Vertical">
            <span>Статус</span>
            <span
              :class="{
                'Product-Tip': true,
                'Is-New': p?.status === 'Заказ выполнен',
                'Few-Products': p?.status === 'Доставляется',
                'Not-Enough-Coins': p?.status === 'Ожидает обработки',
              }"
              >{{ p?.status }}</span
            >
          </div>
        </div>

        <div class="OC-Vertical-Line"></div>

        <div class="OC-Block">
          <div class="OCB-Vertical">
            <span>Покупатель</span>
            <span>{{ p?.name }}</span>
          </div>
          <div class="OCB-Vertical">
            <span>telegram</span>
            <span>{{ p?.telegram }}</span>
          </div>
          <div></div>
          <div class="OCB-Buttons">
            <span
              class="Offer-Button-Next"
              style="width: 100%;"
              @click="
                () => {
                  openModal(p);
                }
              "
              >Сменить статус</span
            >
            <!-- <span
              class="Offer-Button-Prev"
              style="width: 100%;"
              @click="() => {}"
              >Перейти к карточке</span
            > -->
          </div>
        </div>
      </div>
    </div>

    <NewStyledModal
      class="Styled-Modal"
      :showModal="show_modal"
      :hideModal="
        () => {
          show_modal = false;
          product_selected = null;
        }
      "
      :key="show_modal"
    >
      <template v-slot:ModalContent>
        <div class="Offer-Modal" v-if="product_selected != null">
          <span class="Offer-Modal-Title">
            Изменение статуса заказа
          </span>

          <div class="Offer-Modal-Content">
            <div class="Input-Floating-Label">
              <select class="Input-styled" v-model="new_status">
                <option
                  v-for="(f, findx) in store.statuses"
                  :key="f + findx"
                  :value="f"
                  >{{ f }}</option
                >
              </select>
              <span class="Input-Placeholder noselect">Статус</span>
            </div>
          </div>

          <div class="Offer-Modal-Buttons noselect">
            <span
              :class="{
                'Offer-Button-Next': true,
              }"
              @click="updateStatus"
              >Сохранить</span
            >
            <span
              class="Offer-Button-Prev"
              @click="
                () => {
                  closeModal();
                }
              "
              >Отмена</span
            >
          </div>
        </div>
      </template>
    </NewStyledModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { getAdminOrders, updateOrderStatus } from "@/api/store.js";

import SortingWithFiltersPreset from "@/components/CustomElements/SortingWithFiltersPreset.vue";
import NewStyledModal from "@/components/CustomElements/NewStyledModal.vue";

export default {
  components: { SortingWithFiltersPreset, NewStyledModal },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),

    products_hash() {
      let a = this.store.products.map(
        (x) => `${x.id}_${x?.ProductImages?.length}_${x?.status}`
      );
      return `${this.store.products?.length}-${a?.join("-")}`;
    },

    products_filtered_hash() {
      let a = this.store.products_filtered.map(
        (x) => `${x.id}_${x?.ProductImages?.length}_${x?.status}`
      );
      return `${this.updater}-${this.store.products_filtered?.length}-${a?.join(
        "-"
      )}`;
    },

    getProductImages() {
      return this.product_selected?.ProductImages;
    },

    getProducts() {
      return this.store.products;
    },

    product_selected_images_hash() {
      let a = this.product_selected?.ProductImages.map((x) => x.id);
      return `${this.product_selected?.ProductImages?.length}-${a?.join("-")}`;
    },
  },
  data() {
    return {
      show_modal: false,

      filters: {
        sortingOptions: [
          {
            text: "Сортировка по умолчанию",
            val: "default",
          },
        ],
        searchInProperties: ["name", "phone", "telegram"],
        filtersButtons: [
          {
            text: "Ожидает обработки",
            val: "awaiting",
          },
          {
            text: "Доставляется",
            val: "delievery",
          },
          {
            text: "Заказ выполнен",
            val: "done",
          },
        ],
        selectedButton: "",
        selectedSorting: "default",
        selectedSearch: "",
      },

      store: {
        offset: 0,

        products: [],
        products_filtered: [],
        products_counter: 0,

        statuses: ["Заказ выполнен", "Ожидает обработки", "Доставляется"],
      },

      product_selected: null,
      new_status: null,

      updater: 0,
    };
  },
  async created() {
    await this.initialization();
  },
  methods: {
    async initialization() {
      let _store_products = await getAdminOrders({
        selected_button: this.filters.selectedButton,
      });
      this.store.products = _store_products.data;

      // if (this.product_selected != null) {
      //   let a = this.store.products.find(
      //     (x) => x.id === this.product_selected.id
      //   );
      //   console.log(a);
      //   this.product_selected = { ...a };
      // }
    },

    openModal(product) {
      this.product_selected = product;
      this.show_modal = true;
      this.new_status = product.status;
    },

    sortingMethods(sort_name, array) {
      return array;
    },
    handleSearchFilterSortingObject(Array) {
      this.store.products_filtered = Array;
      this.updater += 1;
    },
    handleFilterButton(val) {
      this.filters.selectedButton = val;
      this.initialization();
    },
    handleFilterChanges(obj) {
      this.filters.selectedButton = obj.button;
      this.filters.selectedSearch = obj.search;
      this.filters.selectedSorting = obj.sorting;
    },

    getStyleFromUploadedFile(mat_url) {
      let style = `
      background: url('${mat_url}');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-color: #302E51;
      `;
      return style;
    },

    closeModal() {
      this.show_modal = false;
    },

    async updateStatus() {
      await updateOrderStatus({
        id: this.product_selected.id,
        status: this.new_status,
      });

      await this.initialization();

      this.show_modal = false;
      this.product_selected = null;
      this.new_status = null;
    },
  },
};
</script>

<style scoped>
.OCB-Image {
  position: relative;
  display: flex;

  width: 60px;
  height: 60px;

  border-radius: 12px;
  background-color: #656bff;
}
.OCB-Image > span {
  position: relative;
  display: block;

  margin: auto;

  font-family: "Montserrat";
  font-size: 10px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.01em;
  text-align: center;
  hyphens: auto;

  width: fit-content;
  max-width: 90%;

  height: fit-content;

  word-break: break-all;
  word-wrap: break-word;

  color: white;
}

.OCB-Buttons {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
}
.OC-Vertical-Line {
  position: relative;
  display: block;

  width: 1px;
  height: auto;

  flex-shrink: 0;
  flex-grow: 1;

  background-color: white;
}
.Orders-List {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;

  width: 100%;
  height: fit-content;
}
.Orders-Card {
  position: relative;
  display: flex;

  padding: 20px;

  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;

  width: 100%;
  height: fit-content;

  border-radius: 12px;
  background-color: #1e1b4a;
}
.Orders-Card > .OC-Block {
  position: relative;
  display: grid;

  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;

  width: calc((100% - 40px) / 2);
  height: fit-content;
}
.Orders-Card > div:nth-child(1) {
  grid-template-columns: 60px calc((100% - 60px - 120px) * 0.7) 60px calc(
      (100% - 60px - 120px) * 0.3
    );
}
.Orders-Card > div:nth-child(3) {
  grid-template-columns:
    calc((100% - 60px) * 0.25) calc((100% - 60px) * 0.25) calc(
      (100% - 60px) * 0.25
    )
    calc((100% - 60px) * 0.25);
}

.OCB-Vertical {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;

  width: 100%;
  height: fit-content;
}
.OCB-Vertical > span:nth-child(1) {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #d3caffb2;

  text-transform: uppercase;
}
.OCB-Vertical > span:nth-child(2) {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  color: #ffffffe5;
}

select.Input-styled::-ms-expand {
  display: none;
}
select.Input-styled option {
  background-color: white;
  color: black;
}

.dropzone-container {
  position: relative;
  display: block;

  width: 320px;
  height: fit-content;

  background: transparent;
  border: 1px solid;
  border-color: #413e74;

  border-radius: 10px;
}
.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}

.file-label {
  position: relative;
  display: block;

  padding: 42px 52px;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 400;
  line-height: 23.25px;
  text-align: center;

  cursor: pointer;

  color: #e8e7ec;
}
.preview-container {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;

  width: 100%;
  height: fit-content;
}
.preview-card {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: center;
  gap: 4px;

  width: calc((100% - 30px) / 4);
  height: fit-content;
}

.preview-card > span {
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 400;
  line-height: 23.25px;
  text-align: center;

  word-break: break-word;

  color: #e8e7ec;
}
.Preview-Img {
  position: relative;
  display: block;

  width: 100%;
  height: auto;

  aspect-ratio: 1 / 1;

  border-radius: 10px;
}
.Remove-Button {
  position: absolute;
  display: block;

  cursor: pointer;

  top: 0;
  right: -5px;

  width: 5px;
  height: 5px;

  background: url("./../../assets/img/profile/RemoveButtonMaterials.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.Page-Wrapper {
  position: relative;

  padding-top: 60px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  padding: 50px 8vw;

  width: 100%;
  min-height: 100vh;
  height: fit-content;

  color: white;
}

.Products-Block {
  position: relative;
  display: grid;

  grid-template-columns: repeat(5, calc((100% - 24px * 4) / 5));
  gap: 24px;

  width: 100%;
  height: fit-content;
}

.Product-Card {
  position: relative;
  display: flex;

  padding: 20px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  width: 100%;
  height: auto;

  min-height: 300px;
  /* aspect-ratio: 0.88 / 1; */

  border-radius: 12px;

  background: url("./../../assets/img/Admin/LogoBG.png");
  background-position: 70% 0%;
  background-size: 60%;
  background-repeat: no-repeat;
  background-color: #1e1b4a;

  transition-timing-function: ease-in;
  transition: 0.2s;

  overflow: hidden;
}
.Product-Card:hover {
  background-color: #27235c;
}

.Product-Title {
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #ffffffe5;
}
.Product-Bottom-Block {
  position: relative;
  display: flex;

  margin-top: auto;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  width: 100%;
  height: fit-content;
}
.Product-Tips {
  position: relative;
  display: flex;

  margin-bottom: 4px;

  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 4px;

  width: 100%;
  height: fit-content;
}
.Orders-List .Orders-Card .Product-Tip {
  position: relative;
  display: block;

  padding: 4px 8px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  border-radius: 4px;
}
.Orders-List .Orders-Card .Product-Tip.Not-Enough-Coins {
  color: #ed254e;
  border: 1px solid #ed254e;
  background-color: transparent;
}
.Orders-List .Orders-Card .Product-Tip.Is-New {
  color: #25ed69;
  background-color: #25ed6926;
}
.Orders-List .Orders-Card .Product-Tip.Is-Recommended {
  color: #e924bf;
  background-color: #e924bf26;
}
.Orders-List .Orders-Card .Product-Tip.Few-Products {
  color: #f9dc5c;
  background-color: #f9dc5c26;
}
.Orders-List .Orders-Card .Product-Tip.Is-Archieve {
  color: #d3caffb2;
  background-color: #27235c;
}

.Product-Transfer-Button {
  position: relative;
  display: block;
  cursor: pointer;

  padding: 8px 0px;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;

  border-radius: 4px;

  color: #ffffffe5;
  background-color: #656bff;

  transition-timing-function: ease-in;
  transition: 0.2s;

  opacity: 1;
}
.Product-Transfer-Button:hover {
  background-color: #656bffe5;
}

.Product-Card.No-BG-IMG {
  background: #1e1b4a;
  background-color: #1e1b4a;
}
.Product-Card > *:not(.Product-Background-Image) {
  z-index: 5;
}
.Product-Card:hover > .Product-Background-Image {
  opacity: 0.05;
}
.Product-Background-Image {
  position: absolute;
  display: block;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  transition-timing-function: ease-in;
  transition: 0.2s;

  border-radius: 12px;

  filter: blur(2px);
  opacity: 0.1;
  z-index: 3;
}
</style>

<style scoped>
.Styled-Modal:deep(.SendRequestModal) {
  min-width: 0;
  width: fit-content;
  background: #1e1b4a;
}

.Modal-Card-Wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;

  width: fit-content;
  height: fit-content;
}
.Product-Card.Modal-Card {
  position: relative;

  min-width: 0;
  width: 320px;
  height: auto;
  padding: 0px;

  border-radius: 0px;
  background: transparent;
  background-color: transparent;
}
.Product-Card.Modal-Card .Product-Bottom-Block {
  margin-top: 8px;
}
.Product-Card.Modal-Card .Product-Desc {
  display: block;
  color: #d3caffb2;
}

.Offer-Modal {
  position: relative;
  display: flex;

  padding-top: 12px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;

  width: fit-content;
  height: fit-content;
}

.Offer-Modal-Title {
  position: relative;
  display: block;

  min-width: 320px;
  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;
}
.Offer-Modal-Content {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  min-width: 320px;
  width: fit-content;
  height: fit-content;
}

.Input-Floating-Label {
  position: relative;
  display: block;

  min-width: 320px;
  width: fit-content;
  height: fit-content;
}

.Input-styled {
  position: relative;
  display: block;

  padding: 12px 8px;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #ffffffe5;

  transition-timing-function: ease-in;
  transition: 0.2s;

  background-color: transparent;

  outline: 0;
  outline-offset: 0;

  border-radius: 4px;
  border: 1px solid #d3caff40;
}
textarea.Input-styled {
  min-height: 240px;
}

.Input-styled::placeholder {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #d3caff73;
}
.Input-styled:focus {
  border-color: #656bff;
}
.Input-styled.Input-highligt-error {
  border-color: #ed254e;
}
.Input-styled.Input-highligt-error:focus + .Input-Placeholder,
.Input-styled.Input-highligt-error + .Input-Placeholder {
  color: #ed254e;
}

.Input-Placeholder {
  position: absolute;
  display: block;

  top: 0;
  left: 18px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  transform: translate(0px, -50%);

  transition-timing-function: ease-in;
  transition: 0.2s;

  color: #d3caff4d;
  background: #1e1b4a;
  padding: 0% 2px;
}
.Input-styled:focus + .Input-Placeholder {
  color: #656bff;
}

.Input-Text-Length {
  position: absolute;
  display: block;

  bottom: 0;
  right: 18px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  transform: translate(0px, 50%);

  transition-timing-function: ease-in;
  transition: 0.2s;

  color: #d3caff4d;
  background: #1e1b4a;
  padding: 0% 2px;
}
.Input-styled:focus + .Input-Text-Length {
  color: #656bff;
}

.Offer-Modal-Buttons {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;

  width: 320px;
  height: fit-content;
}

.Offer-Button-Next {
  position: relative;
  display: block;
  cursor: pointer;

  padding: 12px 16px;

  width: 100%;
  height: fit-content;

  flex-shrink: 1;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #ffffffe5;

  transition-timing-function: ease-in;
  transition: 0.2s;

  border-radius: 4px;
  background-color: #656bff;
}
.Offer-Button-Prev {
  position: relative;
  display: block;
  cursor: pointer;

  padding: 12px 16px;

  width: fit-content;
  height: fit-content;

  flex-shrink: 0;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #ffffffe5;

  transition-timing-function: ease-in;
  transition: 0.2s;

  border-radius: 4px;
  background-color: #656bff40;
}
.Offer-Button-Prev:hover {
  background-color: rgba(101, 106, 255, 0.144);
}

.Offer-Text-Before-Confirm {
  position: relative;
  display: block;

  width: 320px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  color: #d3caffb2;
}
.Offer-Text-Before-Confirm .Text-Before-Confirm-Important {
  color: #ffffffe5;
}

.Offer-Modal-Buttons-Child-Wrapper {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  width: 320px;
  height: fit-content;
}

.Offer-Text-Balance-After {
  position: relative;
  display: block;

  width: 320px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: uppercase;

  color: #d3caff4d;
}
.Offer-Text-Error {
  position: relative;
  display: block;

  margin-top: 12px;

  width: 320px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: none;
  color: #ed254e;
}
</style>
